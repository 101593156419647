@import '../../styles/variables';

.CoachingClassForm {
  @include formWithImage;
}

#CoachingClassForm__map {
  height: 100%;
  width: 100%;
}
