$green: #7ed661;
$dark-green: #4dba7f;
$light-green: #7ee8ae;
$pastel-red: #fc7169;
$pastel-green: #4dce76;
$pastel-yellow: #fcda69;
$light-background-color: #f7f7f7;

$black: #000000;
$gray: #99a3ac;
$light-gray: #d2d2d2;

$gradient: linear-gradient(to right, #37db82, $dark-green);
$gradient-light: linear-gradient(to right, #afffd4, $dark-green);

$shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.2);

$theme-color: $dark-green;
$theme-color-dark: $dark-green;
$primary-color: $dark-green;
$secondary-color: $dark-green;

@mixin search {
  display: flex;
  align-items: center;
  margin-bottom: 1em;
  padding: 0.5em;

  input {
    &:focus {
      outline: none;
    }
  }

  i {
    color: grey;
    font-size: 1.5em;
    margin-right: 0.5em;
  }

  input {
    flex-grow: 1;
    color: gray;
  }
}

@mixin list {
  &__title {
    font-size: 2em;
    margin-bottom: 1em;

    span {
      font-size: 0.5em;
      margin-left: 0.5em;
    }
  }

  &__content {
    padding: 2em;
    background-color: white;
    box-shadow: $shadow;
  }

  &__addButton {
    position: fixed;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    width: 2.5em;
    height: 2.5em;
    border-radius: 100%;
    right: 2em;
    bottom: 2em;
    transform: translateY(-40%);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
    user-select: none;
    background-color: $theme-color;
  }

  &__actions {
    text-align: right;
  }

  table {
    $cell-height: 3em;
    font-size: 0.8em;

    table-layout: fixed;
    width: 100%;

    th {
      font-size: 1.25em;
    }

    tr {
      line-height: $cell-height;
      height: $cell-height;

      &:last-child {
        border-bottom: none;
      }
    }

    td {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      img {
        height: $cell-height;
      }

      &.centered {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  i {
    font-size: 1.5em;
    cursor: pointer;
    margin-right: 0.25em;

    &:last-child {
      margin-right: 0;
    }
  }
}

@mixin button {
  background-color: $light-background-color;
  display: flex;
  justify-content: center;
  padding: 1em 2em;
  font-size: 1.25em;
  box-shadow: $shadow;
  cursor: pointer;
}

@mixin formWithImage {
  display: flex;
  flex-direction: column;
  padding: 3em;
  height: 100%;
  width: 100%;

  &__content {
    display: flex;
  }

  &__left {
    display: flex;
    flex-direction: column;
    flex: 2;
    margin-right: 5em;
  }

  &__right {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;

    img {
      align-self: center;
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__fileInput {
    font-size: 1.5em;
    color: $theme-color;
    cursor: pointer;
    margin-top: 1em;

    input[type='file'] {
      display: none;
    }
  }

  &__actions {
    display: flex;
    justify-content: center;
    margin-top: 2em;
  }

  &__button {
    @include button;
  }
}
