@import '../styles/variables';

.Reviews {
  @include list;

  &__search {
    @include search;
  }

  &__actions {
    color: $pastel-red;
  }
}
