.Modal {
  position: fixed;
  z-index: 3;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  padding: 10em 30em;
  background-color: rgba($color: #000000, $alpha: 0.75);

  &__close {
    position: absolute;
    top: 1em;
    right: 1em;
    font-size: 2em;
    color: lightgray;
    cursor: pointer;
  }

  &__content {
    position: relative;
    max-width: 100%;
    max-height: 100%;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    background-color: white;
    overflow-y: auto;
  }
}
