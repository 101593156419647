@import '../styles/variables';

.Sessions {
  @include list;

  &__search {
    @include search;
  }

  &__row {
    &--warn {
      background-color: transparentize($color: $pastel-red, $amount: 0.5);
    }
  }
}
