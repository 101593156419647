@import '../../styles/variables';

.InvoiceGymspot {
  position: fixed;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  overflow: scroll;
  background-color: rgba(247, 247, 247, 0.8);
  z-index: -1;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__pdf {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 3em;
    width: 210mm;
    height: 297mm;
    padding: 2em 5em 2em 5em;
  }

  &__content {
    position: relative;
    background-color: white;
    width: 210mm;
    border-radius: 10px;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.26);
    overflow-y: auto;
  }

  &__header {
    display: flex;
    justify-content: space-between;

    &Logo {
      height: auto;
      width: 10em;
      margin-bottom: 1em;
    }

    &Title {
      align-items: baseline;
      font-size: 2em;
      width: 100%;
      text-align: end;
      margin-bottom: 1em;

      &Id {
        font-size: 0.5em;
        font-style: italic;
      }
    }

    &Date {
      margin-top: 2em;
    }

    &Right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      text-align: right;
    }

    &Recipient {
      margin-top: 2em;
    }
  }

  &__table {
    margin-top: 5em;

    table {
      border-collapse: collapse;
    }

    table,
    th,
    td {
      border: 1px solid black;
    }

    table {
      width: 100%;

      td {
        padding: 1em;
      }
    }

    .centered {
      text-align: center;
    }

    .right {
      text-align: right;
    }
  }

  .Button {
    background-color: $dark-green;
    color: white;
    margin: 0 5em 2em 5em;

    svg {
      path {
        fill: white;
      }
    }
  }

  @media (max-width: 1000px) {
    padding: 0;
  }
}
