@import '../../styles/variables';

.CoachDetails {
  &__title {
    display: flex;
    align-items: baseline;

    label {
      margin-left: 2em;
    }
  }

  &__row {
    &--pending {
      background-color: transparentize($color: $pastel-yellow, $amount: 0.5);
    }

    &--approved {
      background-color: transparentize($color: $pastel-green, $amount: 0.5);
    }

    &--rejected {
      background-color: transparentize($color: $pastel-red, $amount: 0.5);
    }

    .select-wrapper {
      width: 10em;
    }
  }
}
