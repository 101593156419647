@import '../../styles/variables';

.Button {
  display: flex;

  .LoadingDots {
    max-width: 5em;

    &__dot {
      background-color: white;
    }
  }

  &:hover {
    background-color: $dark-green;
  }

  &--disabled {
    background-color: gray;
    cursor: initial;

    &:hover {
      background-color: gray;
    }
  }
}
