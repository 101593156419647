.container {
  height: 100%;
}

.Login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  &__Title {
    font-size: 1.25em;
  }

  &__Inputs {
    .row:first-child {
      margin-bottom: 0;
    }
  }
}
