@import './styles/variables';
@import '../node_modules/materialize-css/sass/materialize.scss';
@import '../node_modules/react-widgets/dist/css/react-widgets.css';

html,
body {
  height: 100%;

  input:not([type]),
  input[type='text'],
  input[type='text']:not(.browser-default),
  input[type='number'],
  input[type='number']:not(.browser-default) {
    height: 2em;
    line-height: 2em;
  }
}

* {
  box-sizing: border-box;
}

body {
  font-family: 'Lato', Arial, Helvetica, sans-serif;
  margin: 0;
}

i {
  font-family: 'Material Icons', 'Comic Sans Ms';
  text-decoration: none;
  font-style: normal;
  user-select: none;
}
