@import '../styles/variables';

.Coaches {
  @include list;

  &__search {
    @include search;
  }

  &__filters {
    margin-bottom: 2.5em;
  }
}
